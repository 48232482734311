import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from "react-helmet";
import Layout from 'components/Layout';
import BlogPost from 'components/pages/BlogPost';
import { Image } from 'components/pages/BlogPost/styled';
import { ImgGatsbyBackground } from 'components/wrappers';
import styled, { css } from 'styled-components';
import { blogPosts } from 'common/blogPostsFeatured';
import BlogBaseCard from 'components/blog/BaseCard';
import BlogSidebar from 'components/blog/BlogSidebar';
import {PostsWrapper} from 'components/pages/Blog/styled';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import FacebookDefaultIcon from 'images/social-share-icons/facebook-white.png';
import TwitterDefaultIcon from 'images/social-share-icons/twitter-white.png';
import LinkedinDefaultIcon from 'images/social-share-icons/linkedin-white.png';
import BlogOptin from 'components/blog/BlogOptin';
import {Clear, BlogRow, ShareButton, ReadNow, DateText, TitleText, UnorderedList, OrderedList, FirstTitle, BottomShare, SectionFull, OptinSection, ColumnHalf, ColumnHalfLast} from 'components/blog/SingleBlogStyle';

import APIImg from 'images/blog-images/shipkoo-api.png';
import APIImgFeatured from 'images/featured-image/shipkoo-api-featured.png';
import OrderApiImg from 'images/blog-images/shipkoo-order-api.png';
import ShipkooImg from 'images/blog-images/shipkoo-services.png';

interface OwnProps {
  location: {
    href: string;
  };
}

const BlogArticle = ({ location }: OwnProps) => {
  const data = useStaticQuery(graphql`
    query APIBG {
      file(relativePath: { eq: "shipkoo-api.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const BackgroundImage = () => <ImgGatsbyBackground fluid={data.file.childImageSharp.fluid} alt="shipkoo shipping api"/>;
  const { href } = location;
  const intl = useIntl()
  return (
    <Layout>
      <Helmet 
        // title="Everything You Need to Know About Shipping APIs | Shipkoo"
        title={intl.formatMessage({ id: "blog-post-meta-title-everything-you-need" })}
         meta={
          [
            {
              name: `description`,
              content: "",
            },
            {
              property: `og:title`,
              // content: "Everything You Need to Know About Shipping APIs",
              content: intl.formatMessage({ id: "blog-post-meta-title-everything-you-need" }),
            },
            {
              property: `og:description`,
              content: "",
            },
            {
              property: `og:image`,
              content: APIImgFeatured,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              name: `twitter:card`,
              content: `summary`,
            },
          ] as JSX.IntrinsicElements['meta'][]
        }
      />
      <BlogPost
        location={location}
        id="everything-you-need-to-know-about-shipping-apis"
        title="Everything You Need to Know About Shipping APIs"
        date="2021-01-06"
        author="Joe Zhang"
        Background={BackgroundImage}
      >
        <BlogRow>
          <BlogSidebar/>
          <div className="column-half last">

            <h5 className="no-top">
              <strong>
                What are shipping APIs?
              </strong>
            </h5>

            <p>
              The term API stands for Application Programming Interface. It is an intermediate link between two applications that allows them to communicate with each other. When it comes to <Link to="/services/ecommerce-fulfillment/">eCommerce fulfillment</Link>, a shipping API integration is especially important. It gives brands the ability to integrate shipping functionality directly into their online stores and platforms, helping simplify the checkout and shipping process.
            </p>

            <p>
              In sum, Shipping APIs are an integral part of creating a positive eCommerce customer experience since they help automate the most complicated aspect of online shopping.
            </p>

            <Image src={APIImg} className="" alt="shipping apis"/>

            <h5>
              <strong>
                Benefits of an API
              </strong>
            </h5>

            <p>
              <strong>Automation</strong><br />
              Manual dependency is reduced and you can schedule tasks well in advance to avoid any confusion and maintain a streamlined workflow.
            </p>

            <p>
              <strong>Efficiency</strong><br />
              With automation being the trend, you can expect to get work done faster and information sharing is much more simplified. Content generated can be pushed automatically.
            </p>

            <p>
              <strong>Synchronization</strong><br />
              Since your platform will now be integrated with other necessary programs and applications, you can synchronize your operations with other apps and optimize your workflow.
            </p>

            <p>
              <strong>Integration</strong><br />
              You can integrate several platforms with one another. For example, you can integrate your store and marketplace with Shipkoo’s account and import your orders directly into our shipping platform. This way, you can manage and ship your orders from one platform.
            </p>

            <Image src={OrderApiImg} alt="shipkoo order api" />

            <h5>
              <strong>
                Why shipping APIs are important for eCommerce
              </strong>
            </h5>

            <p>
              <Link to="/services/ecommerce-fulfillment/">eCommerce fulfillment</Link> is automated to a great extent in today’s world. Therefore, integrating various platforms with the help of APIs can help you reduce manual work and simplify your workflow in a smoother manner. Shipkoo provides a powerful shipping API for eCommerce players to add hassle free worldwide shipping options to their website and end to end shipping functionality to our fulfillment centers or platform.
            </p>

            <p>
              When an eCommerce player like you signs up on Shipkoo, they can integrate their stores or marketplace on our platform. Once you integrate your store and Shipkoo, you open an avenue of options for customization. Here are some common features you will obtain in Shipkoo’s platform:
            </p>

            <UnorderedList>
              <li>
                <strong>Inventory management</strong><br />
                Manage your SKUs, so you know when to reorder from your suppliers
              </li>
              <li>
                <strong>Shipping labels</strong><br />
                Our system helps you to generate shipping label in only a few clicks
              </li>
              <li>
                <strong>Order tracking</strong><br />
                Monitor shipments and share tracking updates with your customers
              </li>
              <li>
                <strong>Multiple fulfillment centers</strong><br />
                Split inventory across multiple locations to cut down on shipping times
              </li>
              <li>
                <strong>Data importing</strong><br />
                Easily upload bulk data from an Excel file directly to our system
              </li>
            </UnorderedList>

            <Image src={ShipkooImg} alt="shipkoo api" />     

            <h5>
              <strong>
                Conclusion
              </strong>
            </h5>

           <p>
              Shipping APIs are a smart approach to learn adequately about them and incorporate them into your system. The most significant function is to streamline your processes. After <a href="https://app.shipkoo.com/register">signing up</a> on Shipkoo, you can Interact with our platform from any interface. Whether it’s your eCommerce platform, warehouse/wms, ERP or order management system, you can get rates, create shipments, download labels and track deliveries, without leaving your main business system.
            </p>

            <BottomShare>
              <div className="column-half">
                <p>Share the article</p>
              </div>
              <div className="column-half last">
                <FacebookShareButton url={href}>
                  <ShareButton><img src={FacebookDefaultIcon} alt="FacebookDefaultIcon" /> <span>Share</span></ShareButton>
                </FacebookShareButton>
                <TwitterShareButton url={href}>
                  <ShareButton><img src={TwitterDefaultIcon} alt="TwitterDefaultIcon" /> <span>Tweet</span></ShareButton>
                </TwitterShareButton>
                <LinkedinShareButton url={href}>
                  <ShareButton><img src={LinkedinDefaultIcon} alt="LinkedinDefaultIcon" /> <span>Share</span></ShareButton>
                </LinkedinShareButton>
              </div>
              <Clear></Clear>
            </BottomShare> 
          </div>  
          <Clear></Clear>
       </BlogRow> 
      </BlogPost>
      <SectionFull>
        <BlogRow>
          <h2>More From Shipkoo</h2>
          <PostsWrapper>
            {blogPosts.map(post => (
              <BlogBaseCard key={`${post.title}-${post.id}`} post={post} />
            ))}
          </PostsWrapper>
        </BlogRow>
      </SectionFull>
      <BlogOptin />
    </Layout>
  );
};

export default BlogArticle;